import { css } from "@emotion/core"

export default css`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: white;
  h1 {
    font-size: 5rem;
    font-family: "Great Vibes", cursive;
    text-align: center;
  }
  h2 {
    font-size: 3rem;
    font-family: "Great Vibes", cursive;
  }
  @media (max-width: 720px) {
    h1 {
      margin-top: 2rem;
    }
    h2 {
      margin-bottom: 5rem;
    }
  }
`
