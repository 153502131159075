import { css } from "@emotion/core"

export default css`
  margin: 3rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  .tile {
    background-color: rgba(255, 255, 255, 0.9);
    margin: 6rem;
    padding: 2rem;
    max-width: 350px;
    width: 100%;
    text-align: center;
    h3 {
      color: #a4a4a4;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      margin: 0;
      padding: 0;
      margin-top: 3rem;
      li {
        padding: 1rem 0.9rem;
        border: 1px solid #c9c9c9;
        a {
          color: #a4a4a4;
        }
      }
    }
  }

  @media (max-width: 720px) {
    .tile {
      margin: 6rem 1rem;
    }
  }
`
