import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Us from "../components/us"
import Share from "../components/share"
import Section from "../components/section"
import Separator from "../components/separator"

import css from "../components/index.css"

export default function Home({ data }) {
  const heroContent = (
    <div css={css}>
      <h1>Arancha & Pablo</h1>
      <h2>25 / 09 / 2021</h2>
    </div>
  )

  return (
    <Layout
      hideHeader={true}
      heroImg={data.file.childImageSharp}
      heroContent={heroContent}
      heroHeight={"100vh"}
    >
      <Section className={"text-block"}>
        <h2>Nos casamos</h2>
        <Separator />
        <p style={{ textAlign: "left" }}>
          Bienvenidos a la página web de nuestra boda. Si habéis llegado hasta
          aquí quiere decir que queremos compartir con vosotros nuestro día más
          feliz.
        </p>
        <p style={{ textAlign: "left" }}>
          Queremos que esta web os sirva de información para todo lo que podáis
          necesitar y os haga partícipes de la preparación de la boda.
        </p>
        <p style={{ textAlign: "left" }}>
          No dudéis de que estamos preparando la boda con toda nuestra ilusión
          para que podamos pasar un día inolvidable juntos.
        </p>
        <p style={{ textAlign: "left" }}>
          Todos y cada uno de vosotros, amigos y familiares, sin importar la
          distancia, sois muy importantes para nosotros y queremos aprovechar
          estas líneas para daros las gracias por querernos, preocuparos por
          nosotros, hacernos felices y hacer de nuestro gran día algo mejor.
        </p>

        <p>Gracias.</p>
      </Section>
      <Us></Us>
      <Share />
    </Layout>
  )
}

export const query = graphql`
  query IndexHeroImageQuery {
    file(absolutePath: { regex: "/hero-image.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 70) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
