import React from "react"
import { Link } from "gatsby"
import useImages from "../hooks/useImages"

import Section from "./section"
import Parallax from "./parallax"
import Separator from "../components/separator"

import css from "./us.css"

export default function Us() {
  const { usParallax } = useImages()

  return (
    <Section>
      <Parallax img={usParallax.childImageSharp} speed={0} />
      <div css={css}>
        <div className={"tile"}>
          <h3>Arancha Palacio</h3>
          <Separator />
          <h4>La novia</h4>
          <p>
            Madrileña de pura cepa. Dí mis primeros pasos y viví durante años en
            Majadahonda. Tuve la suerte de ser la pequeña de mi familia materna
            y la única chica de mi familia paterna, así que siempre he sido la
            mimada de ambas partes. Desde pequeña, desafiaba a quien pudiera a
            jugar conmigo a videojuegos y terminé compitiendo en un equipo
            femenino.
          </p>
          <p>
            Un dia nuestra capitana nos presentó a nuestro nuevo entrenador.
            Quién me iba a decir que años más tarde sería la persona más
            importante para mí! Viajes, competiciones y muchas comilonas
            después, decidí dar el paso. En Japón, en plena bahía de Tokio saqué
            un anillo y le hice la gran pregunta. Teníais que haberle visto la
            cara, no supo qué decir y también sacó un anillo!
          </p>
          <ul>
            <li>
              <Link href={"https://www.facebook.com/Ahcnara.PB"}>
                <span className={"icon-facebook"}></span>
              </Link>
            </li>
            <li>
              <Link href={"https://twitter.com/Yphith"}>
                <span className={"icon-twitter"}></span>
              </Link>
            </li>
            <li>
              <Link href={"https://www.instagram.com/goukithedog"}>
                <span className={"icon-instagram"}></span>
              </Link>
            </li>
          </ul>
        </div>
        <div className={"tile"}>
          <h3>Pablo García</h3>
          <Separator />
          <h4>El novio</h4>
          <p>
            Nací y me crié en Asturias rodeado de una gran familia, buenos
            amigos y una extensa colección de cables, juegos y videoconsolas. La
            cosa se pone interesante cuando unos cuantos años después, en
            Madrid, durante los años de carrera, mientras me dedicaba a lo que
            mejor se me daba (cables y pantallas!), me piden que entrene a un
            equipo femenino de un juego online.
          </p>
          <p>
            Ahí conocí a Arancha y desde entonces congeniamos, nos hicimos
            amigos y unos cuantos años después nos volvimos pareja. Desde
            entonces todo ha sido una gran aventura en la que no ha faltado el
            cariño, las risas y los buenos amigos. Durante un gran viaje en
            Japón, intenté pedirle matrimonio pero ella fué más rápida, y aquí
            estamos!
          </p>
          <ul>
            <li>
              <Link href={"https://www.facebook.com/scottcdf"}>
                <span className={"icon-facebook"}></span>
              </Link>
            </li>
            <li>
              <Link href={"https://twitter.com/nainiwaSSJ"}>
                <span className={"icon-twitter"}></span>
              </Link>
            </li>
            <li>
              <Link href={"https://www.instagram.com/pablo.gargon"}>
                <span className={"icon-instagram"}></span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  )
}
